.intro-grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 10px;
  padding: 10px 50px;
}

.top-selector {
  display: flex;
  padding: 20px 50px;
  align-items: center;
  justify-content: center;
}
.selector-items {
  margin: 0 10px;
  cursor: pointer;
  color: #085afb;
  user-select: none;
}
