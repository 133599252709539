.quiz-preview-container {
  min-height: 90vh;
  background-image: linear-gradient(
    to top,
    #2846f9,
    #1653fb,
    #005efb,
    #0068fb,
    #0071fa,
    #007afa,
    #0083fa,
    #008bf9,
    #0095fa,
    #009ef9,
    #00a6f7,
    #0083fa
  );
  display: flex;
  justify-content: center;
}

.quiz-preview-main {
  width: auto;
}
.quiz-preview-main-title {
  /* border: 1px solid red; */
  font-size: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-weight: 600;
  margin: 0px 50px 0 50px;
  padding: 20px 0;
}
.quiz-preview-main-title > p {
  margin: 0;
}
.quiz-preview-main-instructions > p {
  font-size: 22px;
  font-weight: 500;
  word-spacing: 1px;
  letter-spacing: 0.5px;
  margin: 10px;
}

.quiz-preview-main-instructions > div {
  display: flex;
  align-items: center;
}

.quiz-preview-main-instructions {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin: 40px 50px 0 50px;
}
.quiz-preview-main-instructions > div > svg {
  font-size: 2rem;
  color: #eee600;
  margin-left: 5px;
  margin-bottom: 5px;
}
.quiz-preview-main-instructions > p {
  font-size: 22px;
  font-weight: 500;
  word-spacing: 1px;
  letter-spacing: 0.5px;
  margin: 10px;
}

.quiz-preview-main-bestscore {
  border-radius: 10px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  margin-top: 50px;
}

.quiz-preview-main-buttons {
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 20px;
  border: none;
  width: 130px;
  cursor: pointer;
  text-transform: uppercase;
  color: blue;
  text-align: center;
  transition: all 0.2s ease;
}
.quiz-preview-main-buttons:hover {
}
.quiz-preview-main-buttons:focus {
  transform: translateY(3px);
}
.quiz-preview-main-launchbutton {
  margin-top: 50px;
  background-color: white;
}
.quiz-preview-main-launchbutton:hover {
  background-color: rgb(240, 240, 240);
  
}
.quiz-preview-main-homebutton {
  margin-top: 20px;
  text-decoration: none;
  background-color: #e8e8e8;
}
.quiz-preview-main-homebutton:hover {
  background-color: rgba(255, 255, 255);
 
}


@media only screen and (max-width: 768px) {
  .quiz-preview-container {
    padding-bottom: 120px;
  }
  .quiz-preview-main-title {
    margin: 0px 30px 0 30px;
  }
  .quiz-preview-main-title {
    font-size: 26px;
  }
  .quiz-preview-main-instructions > p {
    font-size: 20px;
    font-weight: 500;
    margin: 10px;
  }

  .quiz-preview-main-instructions {
    color: #fff;
    font-size: 16px;
    margin: 20px 20px 0 20px;
  }
  .quiz-preview-main-instructions > p {
    font-size: 20px;
    font-weight: 500;
    margin: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .quiz-preview-container {
    /* min-height: 130vh; */
  }
  .quiz-preview-main-title {
    margin: 0px 18px 0 18px;
    font-size: 24px;
    padding: 0;
  }
  .quiz-preview-main-instructions {
    color: #fff;
    font-size: 16px;
    margin: 0px 10px 0 10px;
  }

  .quiz-preview-main-title > p {
    margin: 10px 0 10px 0;
  }
  .quiz-preview-main-bestscore {
    margin-top: 20px;
  }
  .quiz-preview-main-instructions > p {
    font-size: 18px;
    font-weight: 500;
    margin: 10px;
  }
  .quiz-preview-main-launchbutton {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 360px) {
  .quiz-preview-main-title {
    margin: 0px 18px 0 18px;
    font-size: 20px;
  }
  .quiz-preview-main-instructions {
    color: #fff;
    font-size: 16px;
    margin: 0px 10px 0 10px;
  }
  .quiz-preview-main-title > svg {
    font-size: 1.8rem;
    margin-right: 5px;
  }
  .quiz-preview-main-instructions > p {
    font-size: 18px;
    font-weight: 500;
    margin: 10px;
  }
}
