.bottom-navbar {
  background-image: linear-gradient(
    to top,
    #2846f9,
    #1653fb,
    #005efb,
    #0068fb,
    #0071fa,
    #007afa,
    #0083fa,
    #008bf9,
    #0095fa,
    #009ef9,
    #00a6f7,
    #0083fa
  );
  width: 100vw;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  height: 65px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-top: 2px solid #fff;
}

.bottom-navbar img {
  height: 40px;
}

