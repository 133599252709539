.icon-style {
  font-family: hotel-solid, sans-serif;
  color: #fff;
  font-size: 40px;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
}

.MuiSelect-icon {
  color: white !important;
}
.MuiSelect-select.MuiSelect-select {
  color: white !important;
}
.MuiInput-underline {
  border-bottom: 2px solid white !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid white !important;
}
.MuiInput-underline:before {
  border-bottom: 1px solid #fff !important;
}

.custom-hint {
  color: #fff;
}

.rv-xy-plot__series--label-text {
  fill: #fff !important;
}
.MuiLinearProgress-bar1Determinate {
  transition: transform 1s linear !important;
}

.button-style{
background-color: black !important;
} 
.button-style:hover{
  background: red;
}