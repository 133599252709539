.outro-page {
  background-image: linear-gradient(
    to top,
    #2846f9,
    #1653fb,
    #005efb,
    #0068fb,
    #0071fa,
    #007afa,
    #0083fa,
    #008bf9,
    #0095fa,
    #009ef9,
    #00a6f7,
    #0083fa
  );
  min-height: 100vh;
}
.outro-page-heading {
  color: #fff;
  font-size: 25px;
  font-weight: 200;
}
.score-rank-column {
  width: 600px;
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 10px;
}
.score-box-head {
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: center;
}
.score-box {
  border: 2px solid #fff;
  display: flex;
  flex-direction: column;
  background-color: #465cff;
  align-items: center;
  padding: 10px 0;
  border-radius: 10px;
}

.score-box-number {
  width: 100%;
  color: #fff;
  font-size: 50px;
  transition: all 0.3s;
  text-align: center;
}
.score-box-number:hover {
  color: rgb(240, 240, 240);
}
.scored {
  font-size: 83px;
}
.score-box-lastDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  background-color: transparent;
  border-top: 2px solid #fff;
  border-right: none;
  border-bottom: none;
  border-left: none;
  width: 100%;
  margin-top: 10px;
}
.score-box-lastDiv:hover > .score-box-lastDiv-text {
  color: rgb(240, 240, 240);
}
.score-box-lastDiv-text {
  color: #fff;
  font-size: 18px;
}
.score-box-lastDiv-icon {
  height: 35px;
}
.rank-box {
  border: 2px solid #fff;
  display: flex;
  flex-direction: column;
  background-color: #465cff;
  align-items: center;
  padding: 10px 0;
  border-radius: 10px;
}

.outro-review-button {
  cursor: pointer;
  border: 2px solid #fff;
  color: #fff;
  font-size: 18px;
  border-radius: 5px;
  padding: 10px 25px;
  background-color: #465cff;
}
.outro-review-button:hover {
  background-color: rgba(255, 255, 255, 0.6);
}
.outro-review-button:focus {
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0 1px #666;
  transform: translateY(4px);
}
.outro-rank-ribbon {
  height: 118px;
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .filter-container {
    position: absolute;
    height: fit-content;
    width: 250px;
  }
  .score-rank-column {
    width: 500px;
  }
}
@media only screen and (max-width: 650px) {
  .filter-container {
    position: absolute;
    height: fit-content;
    width: 250px;
  }
  .score-rank-column {
    width: 400px;
  }
}

@media only screen and (max-width: 550px) {
  .filter-container {
    position: absolute;
    height: fit-content;
    width: 250px;
  }
  .score-rank-column {
    width: 80vw;
  }
}

@media only screen and (max-width: 550px) {
  .filter-container {
    position: absolute;
    height: fit-content;
    width: 250px;
  }
  .score-rank-column {
    width: 80vw;
  }
  .outro-page {
    height: 150vh;
  }
}
