.feedback-button-container{
    position: fixed;
   
    transform: rotate(-90deg);
    top: 50%;
    width: 0;
    z-index: 2;
    transition: all 0.5s;

}
.feedback-button-container a{
  background: #465cff;
  padding: 10px;
  color: #fff;
  border: none;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #fff;

}
.show-feedback-button{
  right: 15px;
}
.hide-feedback-button{
  right: -30px;
}

@media only screen and (max-width: 600px) {
  .feedback-button-container{
      top: 80%;
  }
}
@media only screen and (max-width: 400px) {
    .feedback-button-container{
        top: 70%;
    }
  }