/* prettier-ignore */
.main-nav {
  background-image: linear-gradient(
    to top,
    #2846f9,
    #1653fb,
    #005efb,
    #0068fb,
    #0071fa,
    #007afa,
    #0083fa,
    #008bf9,
    #0095fa,
    #009ef9,
    #00a6f7,
    #0083fa
  );
  position: relative;
  border-bottom: 2px solid #fff;
}
/* prettier-ignore */
.question-nav {
  position: relative;
}
.nav-menu {
  display: flex;
  justify-content: center;
  padding: 20px 5px 20px 0;
  color: #fff;
  align-items: center;
}
.nav-menu-options {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 64px;
}

.nav-text{
  color:#fff
}
.nav-text:hover{
  color:rgb(216,216,216)
}

.nav-heading {
  font-size: 40px;
}

.nav-menu-smallscreen {
  display: flex;
  padding: 10px 20px 10px 20px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-menu-smallscreen > img {
  height: 50px;
}

.navbar-signin-options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(
    to top,
    #2846f9,
    #1653fb,
    #005efb,
    #0068fb,
    #0071fa,
    #007afa,
    #0083fa,
    #008bf9,
    #0095fa,
    #009ef9,
    #00a6f7,
    #0083fa
  );
  width: 250px;
  padding: 10px;
}
.navbar-signin-options-container-username {
  margin-top: 5px;
  color: #fff;
  font-size: 18px;
}
.navbar-signin-options-btn {
  text-decoration: none;
  color: #fff;
  padding: 5px 20px;
  border: 1px solid #fff;
  border-radius: 10px;
  margin-top: 5px;
  width: 70%;
  text-align: center;
}
.navbar-signin-options-btn:hover {
background: rgba(216,216,216,0.2);
}
.navbar-profile-pic {
  height: 40px;
  border-left: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  padding-left: 10px;
  background-color: transparent;
  cursor: pointer;
}

.nav-heading-backarrow{
  color:#fff;
  position:absolute;
  left:50px;
  top:25px;
  z-index:1;
  cursor: pointer;

}

.nav-heading-backarrow > button > svg {
  font-size: 40px;
}

.nav-ribbon{
  height:40px;
  margin-right: 10px;
  fill: #fff;
}
.nav-ribbon:hover{
 fill: rgb(216,216,216);
}

@media only screen and (max-width: 1025px) {
  .nav-heading-backarrow{
   position: inherit;
   padding-right: 10px;
   margin-left: -10px;
  }

  .nav-heading-backarrow > button > svg {
    font-size: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .nav-heading-backarrow{
   position: inherit;
   padding-right: 10px;
   margin-left: -10px;
  }

.nav-text{
  font-size: 13px;
}

  .nav-heading-backarrow > button > svg {
    font-size: 30px;
  }
  .nav-menu-options{
    right: 20px;
  }
}


@media only screen and (max-width: 300px) {
  .nav-heading-backarrow{
    position: inherit;
    padding-right: 10px;
    margin-left: -10px;
    font-size: 50px !important;
   }
  .nav-heading-backarrow{
    margin-left: 0px;
    padding-right: 5px;
   }
  .nav-menu-smallscreen{
    padding: 10px 0;
  }
}