.myscore-page {
  background-image: linear-gradient(
    to top,
    #2846f9,
    #1653fb,
    #005efb,
    #0068fb,
    #0071fa,
    #007afa,
    #0083fa,
    #008bf9,
    #0095fa,
    #009ef9,
    #00a6f7,
    #0083fa
  );
  min-height: 100vh;
  display: flex;
  justify-content: center;

  /* Account for bottom nav blocking content. See bottomNav.css for height */
  padding-bottom: 75px;
}
.myscore-box {
  width: 500px;
  position: relative;
}
.myscore-gobackbutton{
    color: #fff;
    font-size: 40px;
    float: left;
    cursor: pointer;
    position: absolute;
    left: calc(100% - 540px);
    top: 35px;
}
.myscore-box-topbar {
  border: 2px solid #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 5px;
  margin-top: 30px;
}
.myscore-box-topbar button {
  text-align: center;
  padding: 5px 10px;
  color: #fff;
  border: 0;
  font-size: 1rem;
  cursor: pointer;
}

.myscore-box-totalscore {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.418);
  text-align: center;
  color: #fff;
  padding: 10px;
}
.myscore-box-totalscore > span:nth-of-type(1) {
  font-size: 55px;
  margin-bottom: 5px;
}
.myscore-box-totalscore > span:nth-of-type(2) {
  font-size: 14px;
  margin-bottom: 10px;
}
.myscore-recents {
  margin-top: 35px;
}
.myscore-recents > span {
  color: #fff;
}
.myscore-recents-list {
  margin-top: 10px;

  overflow: auto;
}
.myscore-recents-list-item {
  border: 2px solid #fff;
  border-radius: 5px;
  margin-top: 5px;
  color: #fff;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.418);
}

.myscore-recents-list-item >  span:first-child {
  flex: 1 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topscore-ranks {
  margin-top: 35px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
}
.topscore-ranks-second {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.topscore-ranks-second > .topscore-ranks-third-pst {
  height: 120px;
}

.topscore-ranks-third-pst {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.418);
}
.topscore-first {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.topscore-first > .topscore-ranks-third-pst {
  height: 160px;
}

.topscore-ranks-third {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.topscore-ranks-third > .topscore-ranks-third-pst {
  height: 80px;
}

.topscore-list {
  margin-top: 10px;

  height: 350px;
  overflow: auto;
}
.topscore-list-items {
  background-color: rgba(255, 255, 255, 0.418);
  border: none;
  display: flex;
  align-items: center;
  padding: 10px;
  color: #fff;
  margin-top: 5px;
  justify-content: space-between;
  border-radius: 5px;
  position: relative;
}
.topscore-list-item-ranks {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.815);
  font-size: 13px;
  color: #005efb;
  height: 100%;
  width: 50px;
  left: 0;
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  padding-left: 5px;
}

.topscore-user-photo {
  align-self: center;
}

.achivementScore-heading {
  color: #fff;
  font-size: 20px;
  margin-top: 30px;
  text-align: center;
}
.achivementScore-list {
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

@media only screen and (max-width: 700px) {
  .myscore-box {
    width: 350px;
  }
  .myscore-gobackbutton{
    left: calc(100% - 375px);
  }
}
@media only screen and (max-width: 420px) {
  .myscore-box {
    width: 300px;
  }
  .myscore-gobackbutton{
    top: 0;
    left: 0;
    margin: 10px 0;
  }
  .myscore-box-topbar{
    margin-top: 40px;
  }
}
